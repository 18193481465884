.fixed-img {
  background-color: black;
  background: url("../../images/landing/background.svg") no-repeat;
  height: 100%;
  width: 100%;
  position: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  // z-index: -1;
}
// * {
//   border: 1px solid orange;
// }
.landing,
.audit {
  // * {
  //   border: 1px solid orange;
  // }
  background-color: black;
  color: $whiteColor;
  text-align: center;

  @media (min-width: 1800px) {
    background-size: cover;
  }

  .music-btn {
    position: fixed;
    bottom: 0;
    z-index: 1111;
    color: $whiteColor;
    &:hover {
      color: $pinkColor;
    }
    img {
      width: 0.8rem;
      margin-bottom: 0.1rem;
    }
  }
  .heading {
    font-size: 1.5rem;
    margin-top: 1rem;
    font-weight: bold;
  }

  p {
    color: rgba($color: $whiteColor, $alpha: 0.8);
  }

  .desc {
    width: 26rem;
    margin: 0 auto;
    margin-bottom: 2rem;
    @media screen and (min-width: 1100px) and (max-width: 1281px) {
      margin-bottom: 1rem;
    }
  }

  .banner {
    text-align: left;
    margin-top: -1.2rem;
    margin-bottom: -7rem;
    // background: url('../../images/landing/topbanner.svg') no-repeat center;
    // background-size: cover;

    .logo-img {
      position: relative;
      top: -5rem;
      z-index: 11111;
      margin-bottom: -7rem;
      width: 18rem;
    }

    .laptop,
    .mob,
    .mobile-video,
    .laptop-loader,
    .mobbile-show,
    .mob-loader {
      display: none;
    }
    .main {
      width: 58%;
      margin: 0 auto;
    }

    text-align: center;

    h1 {
      font-size: 2.5rem;
      color: $pinkColor;
      font-weight: bold;
      font-family: $monteserrat;
    }

    h4 {
      color: $pinkColor;
      font-size: 1.3rem;
    }

    .social-info {
      width: 100%;
      position: absolute;
      top: 3%;

      span {
        display: block;
      }
    }

    .btn {
      background: $pinkColor;
      border: 1px solid $pinkColor;
      margin-left: 1rem;
      transition: color 300ms ease-in-out;
      padding: 0.75rem 1.5rem;
      color: #000000;

      &:hover {
      }
    }

    .active-btn {
      background: none;
      color: $whiteColor;
      img {
        width: 0.8rem;
        margin-right: 0.5rem;
        margin-bottom: 0.1rem;
      }
    }
  }

  .supported-div {
    position: relative;
    z-index: 11;
    margin: 0 0 0 auto;
    right: 3rem;
    top: 0;
    span {
      font-size: 1.12rem;
      text-align: right;
    }
    hr {
      border: 1px solid;
      margin-top: 0.5rem;
    }
    img {
      display: block;
      margin-top: 1rem;
    }
    @media (max-width: 767px) {
      right: 0;
      span {
        text-align: center;
      }
    }
  }
  .sub-main {
    position: relative;
    top: -16rem;
    width: 50%;
    left: 3rem;
    @media (max-width: 1921px) {
      top: -12rem;
    }
    @media (max-width: 1600px) {
      top: -8rem;
    }
    img {
      margin-bottom: 0.5rem;
      width: 25%;
      display: block;
    }
  }
  .every {
    z-index: 1;
    position: absolute;
    top: 7rem;
    right: 3rem;
  }
  .arrow-gif {
    width: 4%;
    position: relative;
    /* margin-bottom: 0rem; */
    top: 1rem;
    left: -2rem;
    height: 1%;
    z-index: 1;
    mix-blend-mode: difference;
  }
  .game {
    margin-top: -13rem;
    // background: url('../../images/landing/gamebg.png') no-repeat center;
    background-size: 100%;
    font-family: $monteserrat;
    @media (max-width: 1800px) {
      margin-top: -10rem;
    }
    @media (max-width: 1300px) {
      margin-top: -8rem;
    }
    @media (max-width: 767px) {
      margin-top: 0;
    }
    span {
      color: $yellow;
      font-weight: 800;
    }
    .logo {
      height: 7rem;
    }
    .row {
      margin-top: 2rem !important;
    }
    img {
      height: 12rem;
      margin-bottom: -2rem;
      @media (max-width: 767px) {
        height: 6rem;
      }
    }
    .sub-games {
      max-width: 60rem;
      margin: 7rem auto 11rem auto;
      .images-divv {
        display: flex;
        justify-content: space-between;
      }
      img {
        max-width: 100%;
        height: 5rem;
      }
      .cross {
        height: 100%;
        width: 2rem;
        margin-top: 1rem;
      }
      .vertical {
        border-left: 1px solid $whiteColor;
        position: relative;
        top: 12px;
      }
    }
    .videoContainer {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      .thumbnail {
        height: 100%;
      }
      .mute {
        display: block;
        position: absolute;
        left: 1rem;
        bottom: 15%;
        height: 1.5rem;
        z-index: 999;
        @media (max-width: 767px) {
          height: 1rem;
          bottom: 25%;
        }
      }
      img {
        display: none;
      }
      &:hover {
        img {
          display: block;
        }
      }
    }
    .btn {
      color: $whiteColor;
      background: #2a2a2a;
      padding: 0;
      margin-left: 0.6rem;
      position: relative;
      z-index: 5;
      img {
        display: initial;
        margin: 0;
      }
    }

    @media (max-width: 1200px) {
      .right-btn {
        right: 11%;
      }
      .left-btn {
        left: 11%;
      }
    }
    .sub-game {
      background: url("../../images/landing/race.svg");
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      padding: 2rem 0.4rem;
      border-radius: 1rem;
      width: 60rem;
      margin: 0 auto;

      video {
        border-radius: 1rem;
        visibility: hidden;
      }
    }
    @media (min-width: 1500px) {
      .css-doq0dk {
        width: 1500px;
        .sub-game {
          width: 75rem;
        }
      }
    }
    .mob-img2,
    .mob-last-img {
      display: none;
    }
    .last-img {
      margin-top: 0rem;
    }

    // .btn {
    //   // background: none;
    //   // border: 1px solid $pinkColor;
    //   margin-left: 1rem;
    //   padding: 0.75rem 1.5rem;
    //   color: $whiteColor;

    //   &:hover {
    //     // background: $pinkColor;
    //   }
    // }
  }

  .battle {
    text-align: left;
    margin-top: 3rem;
    .mobile-row {
      display: none;
    }
    .desc {
      margin: 0;
    }
    .sub {
      width: 90%;
      margin: 0 auto;
      position: absolute;
      top: 3%;
      left: 6%;
    }
    img {
      width: 100%;
    }
    .batle1 {
      // background: url('../../images/landing/battle.png') no-repeat center;
      // background-size: 100%;
      // padding-top: 1px;
      // height: 55rem;
      width: 98%;
    }
    .batle2 {
      // background: url('../../images/landing/battle1.png') no-repeat center;
      // background-size: 100%;
      width: 98%;
      padding-top: 1px;
      // height: 55rem;
      margin: 0 0 0 auto;
    }
    .btn {
      padding: 0.75rem 2.5rem;
      color: $whiteColor;
      visibility: hidden !important;
    }
  }
  .roadmap {
    background: url("../../images/landing/road.png") center;
    background-repeat: no-repeat;
    background-size: 100%;
    text-align: initial;
    margin-top: 4rem;
    .road-mob {
      display: none;
    }

    .mobile-scroler {
      display: none;
    }
    .main-road {
      display: flex;
      justify-content: space-around;
      align-items: end;

      img {
        width: 37rem;
      }
    }
    .sub-road {
      display: flex;
      text-align: initial;

      div {
        width: 24%;
      }
      h2 {
        font-size: 25px;
        font-weight: bold;
      }
    }
    .sec-road {
      margin-top: 4rem;
    }
    h1 {
      font-size: 6rem;
      font-weight: bold;
      margin-bottom: 2rem;
    }
    .line-img {
      width: 100%;
    }
    ul {
      border-bottom: 0;
      li {
        font-weight: bold;
      }
    }
    @media (max-width: 1700px) {
      background: url("../../images/landing/road2.png") center;
      background-repeat: no-repeat;
      background-size: 100%;

      img {
        width: 26rem !important;
      }
      h1 {
        font-size: 3rem;
      }
      h2 {
        font-size: 1.2rem;
      }
      li {
        font-size: 14px;
      }
      .sub-road {
        div {
          width: 35%;
        }
      }
      .sec-road {
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
    }
    @media (max-width: 1300px) {
      background: url("../../images/landing/road2.png") center;
      background-repeat: no-repeat;
      background-size: 100%;

      img {
        width: 22rem !important;
      }
      h1 {
        font-size: 2rem;
      }
      h2 {
        font-size: 1rem !important;
      }
      li {
        font-size: 10px;
      }
      .sub-road {
        div {
          width: 35%;
        }
      }
      .sec-road {
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
    }
    hr {
      border-top: 1px solid #7f7f7f;
      width: 55rem;
      margin-bottom: 3rem;
    }

    .row {
      width: 55rem;
      margin: 0 auto;
      text-align: initial;

      .box {
        margin-bottom: 2rem;

        h5 {
          font-size: 1rem;
          margin-bottom: 0;
          text-transform: uppercase;
        }

        span {
          font-size: 0.9rem;
          color: rgba($color: $whiteColor, $alpha: 0.8);
          width: 95%;
          display: inline-block;
        }
      }
    }
  }
  .mobil-video {
    display: none;
  }
  .space {
    // background: url('../../images/landing/space.svg') no-repeat center;
    // background-size: 100%;
    position: relative;
    top: -45rem;
    .sub-space {
      display: flex;
      justify-content: space-between;
      width: 62rem;
      margin: 0 auto;
      margin-bottom: 3rem;
      margin-top: 4rem;
      h3 {
        font-size: 1rem;
      }
      p {
        font-size: 0.9rem;
      }
      .charge {
        background: url("../../images/landing/charge.svg") no-repeat center;
        background-size: 100% 100%;
        display: flex;
        justify-content: space-between;
        width: 48%;
        align-items: center;
        padding: 27px;
        div {
          width: 79%;
          text-align: left;
          p {
            margin-bottom: 0;
          }
        }
        img {
          width: 3rem;
        }
      }
      .park {
        background: url("../../images/landing/park.svg") no-repeat center;
        background-size: 100% 100%;
        display: flex;
        width: 48%;
        justify-content: space-evenly;
        align-items: center;
        div {
          width: 79%;
          text-align: left;
          p {
            margin-bottom: 0;
          }
        }
        img {
          width: 4rem;
        }
      }
    }
    .btn {
      padding: 1.75rem 1.5rem;
      color: $whiteColor;
      // width: rem;
      text-transform: uppercase;
      margin-bottom: 9rem;
    }
  }
  .vr {
    background: url("../../images/landing/vrbg.svg") no-repeat center;
    background-size: cover;
    margin-bottom: 4rem;
    .sub-vr {
      max-width: 60rem;
      margin: 0 auto;
    }
    .img-hover-zoom {
      height: 100%;
      overflow-x: hidden;
      width: 100%;
      padding-top: 9rem;
      @include breakpoint-down(small) {
        padding-top: 3rem;
      }

      img {
        transition: transform 1.5s ease;
        padding-bottom: 5rem;
      }
    }
    /* [3] Finally, transforming the image when container gets hovered */
    .img-hover-zoom:hover img {
      transform: scale(2.6);
    }
    .btn {
      // background: #2a2a2a;
      color: $whiteColor;
      display: block;
      margin: 2.5rem auto;
      width: 17rem;
      padding: 0.75rem 0.25rem;

      &:hover {
        cursor: url("../../images/cursor.svg"), auto !important;
        // background: $pinkColor;
      }
    }
  }

  .ido {
    .row {
      text-align: initial;
      width: 60rem;
      margin: 0 auto;

      p {
        width: auto;
      }

      .line {
        display: block;
        margin: 0 auto;
        width: 1px;
        background: #7f7f7f;
        height: 16rem;
      }

      .heading-div {
        display: flex;
        justify-content: space-between;
      }

      .last-div {
        display: flex;
        justify-content: space-between;

        span {
          font-size: 0.9rem;
          color: rgba($color: $whiteColor, $alpha: 0.8);
        }
      }

      .btn {
        padding: 0.375rem 1.5rem;
        display: block;
        margin: 0 0 0 auto;
      }

      .actve {
        // background: $pinkColor;
        color: $whiteColor;
      }

      .inactive {
        color: rgba($color: $whiteColor, $alpha: 0.6);
        background: #2a2a2a;
      }
    }
  }

  .runway {
    background: url("../../images/landing/lightblueBg.svg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 3rem;
    padding-bottom: 2rem;
    margin-top: 4rem;

    .desc {
      margin-bottom: 0;
    }

    h1 {
      color: $pinkColor;
    }

    img {
      display: block;
      width: 90%;
    }

    .active {
      color: $whiteColor;
    }
  }

  .mode {
    margin-top: 6rem;
    .mob-img-mode {
      display: none;
    }
    .sub-mode {
      display: flex;
      justify-content: space-between;
      align-items: center;

      div {
        width: 40%;
        margin: 0 auto;
        text-align: initial;
        .btn {
          padding: 1.2rem 2.5rem;
          color: $whiteColor;
          visibility: hidden;
        }
      }
      img {
        width: 50%;
        &:hover {
          // border: 1px solid $pinkColor;
          // transform: scale(1.1);
        }
      }
    }
    // .mode-div {
    //   display: flex;
    //   width: 60rem;
    //   justify-content: space-between;
    //   margin: 0 auto;

    //   .sub-mode {
    //     width: 48%;
    //   }

    //   .survival {
    //     background: url('../../images/landing/race.svg') center no-repeat;
    //     padding: 13px 0px 8px 0px;
    //     background-size: 100%;
    //     margin-bottom: 1rem;

    //     video {
    //       width: 93%;
    //       border-radius: 6px;
    //     }
    //   }

    //   .jet-view {
    //     width: 75%;
    //     margin-bottom: 1rem;
    //   }

    //   h5 {
    //     font-size: 1.4rem;
    //   }

    //   p {
    //     width: 80%;
    //     margin: 0 auto;
    //     font-size: 0.9rem;
    //     margin-bottom: 1rem;
    //   }

    //   .btn {
    //     // background: #2a2a2a;
    //     color: $whiteColor;
    //     padding: 0.35rem 1rem;
    //     margin-left: 0.6rem;
    //     margin-bottom: 2rem;

    //     img {
    //       width: 1rem;
    //     }

    //     &:hover {
    //       // background: $pinkColor;
    //     }
    //   }
    // }
  }

  .partner {
    background: url("../../images/landing/partnershipbg.png") no-repeat center;
    background-size: 100%;
    .btn {
      // background: #2a2a2a;
      color: $whiteColor;
      padding: 0.75rem 1rem;
      margin-left: 0.6rem;
      margin-bottom: 2rem;
      width: 14rem;

      &:hover {
        // background: $pinkColor;
      }
    }

    .active {
      // background: $pinkColor;
    }

    .row {
      width: 60rem;
      margin: 0 auto;
      margin-bottom: 1rem;

      img {
        width: 8rem;
        margin-bottom: 2rem;
      }
    }
    .last-row {
      margin-bottom: 10rem;
    }
  }
  .right-arrow {
    position: absolute;
    top: 45%;
    right: 1%;
    width: 7rem;
  }
  .left-arrow {
    position: absolute;
    top: 45%;
    left: 1%;
    width: 7rem;
  }
  .nft {
    max-width: 100rem;
    padding: 11rem 0 3rem 0;
    margin-bottom: 3rem;
    .slick-slider {
      width: 900px;
      margin: 0 auto;
    }
    .slick-next {
      right: -20%;
      width: 9rem;
      height: auto;
      @media (max-width: 1200px) {
        right: -10%;
      }
      @media (max-width: 767px) {
        width: 7rem;
      }
      &:hover {
        cursor: url("../../images/cursor.svg") 2 2, auto !important;
      }
    }
    .slick-prev {
      left: -20%;
      width: 9rem;
      height: auto;
      @media (max-width: 1200px) {
        left: -10%;
      }
      @media (max-width: 767px) {
        width: 7rem;
      }
      &:hover {
        cursor: url("../../images/cursor.svg") 2 2, auto !important;
      }
    }
    .text {
      text-align: left;
      p {
        margin: 1rem 0;
        text-transform: uppercase;
        font-weight: 400;
        font-size: 1.3rem;
      }
      // .attributes {
      //   width: 70%;
      //   margin-top: 3rem;
      //   p {
      //     color: $whiteColor;
      //     font-weight: 600;
      //     font-style: italic;
      //   }

      //   .containers {
      //     background-color: #112f35;
      //   }

      //   .barCompleted {
      //     background-color: $pinkColor;
      //     width: 80%;
      //     height: 11px;
      //   }

      //   .label {
      //     visibility: hidden;
      //   }
      // }
      .activeBtn {
        background: none;
        color: #ffffff;
        img {
          display: inline !important;
        }
      }

      .heading {
        font-size: 3rem;
        margin-top: 16rem;
        font-weight: 600;
        font-family: $ethno;
      }

      .btn {
        color: $whiteColor;
        background: #2a2a2a;
        padding: 0.75rem 1rem;
        margin-left: 0.6rem;
        position: relative;
        z-index: 1;
      }
    }
    // background: url('../../images/landing/darkBg.svg') center no-repeat;
    @media (max-width: 767px) {
      img {
        max-width: 100%;
        height: 85%;
        margin: auto;
      }
      .left-butn {
        width: auto;
        position: absolute;
        margin-top: 10rem;
        margin-left: -1rem;
        img {
          width: 7.5rem;
        }
      }
      .right-butn {
        position: absolute;
        margin-top: 10rem;
        left: 72%;
        width: auto;
        img {
          width: 8rem;
        }
      }
      .text {
        text-align: center;
        .playBtn {
          margin-bottom: 2rem;
        }
        .heading {
          margin-top: -4rem;
        }
      }
    }

    // .indicators {
    //   display: none !important;
    // }

    // .react-slideshow-container .default-nav {
    //   display: none !important;
    // }
  }
  .death {
    margin: 3rem 0;
    .deathMatch,
    .freePlay {
      background: url("../../images/landing/deathMatchBg.jpg") center;
      background-size: cover;
      padding: 7rem 4rem;
      display: flex;
      justify-content: end;
      text-align: right;
      align-items: center;
    }
    .freePlay {
      background: url("../../images/landing/freePlayBg.jpg") center;
      background-size: cover;
      justify-content: start;
      text-align: left;
    }

    p {
      font-size: 1.2rem;
      font-weight: 500;
      max-width: 35rem;
      margin: 2rem 0;
      @media (max-width: 1280px) {
        max-width: 26rem;
      }
    }
    @media (max-width: 767px) {
      margin-top: -14rem;
      .deathMatch {
        background: url("../../images/landing/deathMatchBgMobile.png") no-repeat center;
        background-size: 100%;
        padding-top: 40rem;
        text-align: center;
        margin-top: 5rem;
      }
      .freePlay {
        background: url("../../images/landing/freePlayBgMobile.png") no-repeat center;
        background-size: 100%;
        padding-bottom: 10rem;
        margin: 3rem 0 30rem 0;
        text-align: center;
        .free-img {
          margin-top: 37rem;
        }
      }
      .absMobile {
        position: absolute;
        left: 0;
        margin-top: -32rem;
      }
      p {
        margin-top: 14rem;
      }
    }
  }
  .discords {
    h1 {
      color: $pinkColor;
      font-weight: 900;
      margin-bottom: 2rem;
    }
    img {
      height: 8rem;
    }

    .telegram,
    .twitter,
    .discord {
      background: url("../../images/landing/telegramsBg.svg") no-repeat center;
      height: 14rem;
      padding: 3rem 1rem 1rem 1rem;
      margin-left: 2rem;
      @media (max-width: 767px) {
        margin-left: 0;
        .playBtn {
          padding: 0.75rem;
        }
      }
    }
    .telegram {
      .btn {
        width: 12rem;
      }
    }
    .twitter {
      background: url("../../images/landing/twitterBg.svg") no-repeat center;
    }
    .discord {
      background: url("../../images/landing/discordsBg.svg") no-repeat center;
    }
    @media (max-width: 767px) {
      margin-top: -25rem;
      h1 {
        font-size: 1.8rem;
      }
    }
  }
  .season {
    margin-top: 5rem;
    margin-bottom: 14rem;
    text-align: left;
    // background: url('../../images/landing/purpleBg.png') no-repeat center;
    // background-position: center;
    // background-size: 100%;
    // padding: 19rem 0 24rem 0;

    h2 {
      font-family: $monteserrat;
      font-weight: bold;
      margin-top: 5rem;
    }
    h1 {
      font-family: $tit;
      color: #f46b1a;
      font-size: 3.5rem;
    }
    p {
      width: 70%;
    }
    .btn {
      margin: 10px;
      padding: 15px 45px;
      color: white;
      font-weight: 600;
      border: 1px solid #f46b1a;
    }
    .active {
      background: #f46b1a;
    }
    .followup-img {
      width: 100%;
    }
    .followup-mob {
      display: none;
    }
    @media (max-width: 1300px) {
      h2 {
        margin-top: 1rem;
      }
      h1 {
        font-size: 2rem;
      }
    }
    @media (max-width: 820px) {
      text-align: center !important;
      margin: 5rem 0 10rem 0;
      .followup-mob {
        display: initial;
      }
      .followup-web {
        display: none;
      }
      h2 {
        font-size: 1.2rem;
        margin-top: 3rem;
      }
      img {
        width: 80%;
      }
      p {
        margin: 1rem auto;

        width: 95%;
      }
    }
  }
  .team {
    margin-top: -5rem;
    color: $whiteColor;
    h1 {
      color: $pinkColor;
      font-weight: 900;
      margin-bottom: 2rem;
    }
    img {
      max-width: 70%;
    }
    .sub-head {
      font-size: 1.7rem;
      width: 70%;
      margin: auto;
      margin-bottom: 6rem;
      @media (max-width: 767px) {
        width: 100%;
        font-size: 1.3rem;
        margin-bottom: 3rem;
      }
    }
    .col-md-4 {
      margin-bottom: 3rem;
      .personn {
        height: 15rem;
      }
      .name,
      .name-two {
        // transform: matrix(0.98, -0.17, 0.17, 0.98, 0, 0);
        font-size: 2rem;
        font-weight: 900;
        font-family: $tit;
        color: $whiteColor !important;
        margin-top: -1.8rem;
        span {
          padding: 0 2rem;
          @media (max-width: 1300px) {
            padding: 0 1rem;
          }
        }
        .umar {
          background-color: #008cb7;
        }
        .usman {
          background-color: #2200ff;
        }
        .ali {
          background-color: #00b73d;
        }
        .ayse {
          background-color: #4800b7;
        }
        .hasan {
          background-color: #f77613;
        }
      }
      .tag {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 1rem;
        p {
          margin: 0;
          margin-right: 1rem;
          font-weight: 600;
          color: $whiteColor;
          font-size: 1.2rem;
        }
      }
    }
  }
  .guardians {
    margin: 8rem 0 5rem 0;
    .partners {
      align-self: center;
      text-align: left;
      padding: 0 0 0 5rem;

      h1 {
        color: $pinkColor;
        font-weight: 900;
        margin-bottom: 1rem;
      }
      p {
        color: $whiteColor;
        font-size: 1.2rem;
      }
      .bg {
        background: transparent;
        color: $whiteColor;
      }
    }
    @media (max-width: 767px) {
      margin-top: 6rem;
      .partners {
        padding: 0;
        text-align: center;
        h1 {
          margin-top: 3rem;
          font-size: 1.5rem;
        }
        .bg {
          margin-top: 2rem;
        }
      }
    }
  }
  .partners {
    h1 {
      color: $pinkColor;
      font-weight: 900;
      margin-bottom: 2rem;
    }
    img {
      width: 16rem;
      @media (max-width: 767px) {
        width: 9rem;
      }
    }
  }
  .getGame {
    margin-top: 3rem;
    .row {
      width: 80%;
      margin: 0 auto;
    }
    .text {
      text-align: left;
      padding-top: 5rem;
      h1 {
        font-size: 2.5rem;
        font-weight: 800;
      }
      p {
        margin-bottom: 1.5rem;
        color: $whiteColor;
        width: 80%;
      }

      .images-div {
        display: flex;
        justify-items: center;
        img {
          width: 90%;
          margin-top: 1rem !important;
        }
      }
      @media (max-width: 767px) {
        text-align: center;
        p {
          width: 100%;
        }
      }
    }
  }
  .audit {
    margin: 8rem 0 11rem 0;
    .sub-audit {
      max-width: 70rem;
      margin: 3rem auto;
      justify-content: space-evenly;
      .btn {
        color: $whiteColor;
        padding: 1.75rem 1rem;
        margin-top: 2rem;
      }
      .imageBox {
        position: relative;
        float: left;
        min-width: 20rem;

        .hoverImg {
          position: absolute;
          left: 0;
          top: 0;
          display: none;
          img {
            max-width: 100%;
            background: #b3b3b9;
            height: 10rem;
          }
          .eye {
            position: absolute;
            width: 4rem;
            height: 3rem;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            cursor: pointer;
          }
        }
      }
      .imageBox:hover .hoverImg {
        display: block;
      }
    }
    h1 {
      margin-bottom: 2rem;
      font-size: 2rem;
    }
    @media (max-width: 767px) {
      img {
        margin-bottom: 3rem;
        width: 90%;
      }
      .imageBox {
        float: none !important;
        .hoverImg {
          width: 100%;
        }
      }
    }
  }
  .trailer {
    background: url("../../images/landing/trailer.svg") no-repeat;
    background-position: center;
    background-size: 100%;
    margin-top: -4rem;
    padding: 10rem 0;
    margin-bottom: -7rem;
    @media (max-width: 1281px) {
      background-size: cover;
    }
    img {
      width: 13rem;
    }
    p {
      font-size: 1.2rem;
      width: 45%;
      margin: auto;
      color: $whiteColor;
      margin-bottom: 1rem;
    }
    @media (max-width: 767px) {
      background: url("../../images/landing/trailerTwo.png") no-repeat;
      background-size: cover;
      padding: 0;
      margin-top: -7rem;
      p {
        width: 100%;
      }
      img {
        width: 60%;
      }
      .col-md-6 {
        padding: 3rem 0 10rem 0;
      }
    }
  }
  .jet {
    background: url("../../images/landing/getjetvg.svg");
    background-position: center;
    background-size: 100%;

    // background-repeat: no-repeat;
    // background-size: cover;
    margin-top: 5rem;
    padding-bottom: 2rem;

    .btn:hover {
      transform: scale(1.1);
    }

    .sub-jet {
      display: flex;
      justify-content: space-between;
      text-align: initial;
      width: 55rem;
      margin: 0 auto;

      .desc {
        margin: 0;
        width: 80%;
        margin-bottom: 2rem;
      }
    }
    .main-div {
      // display: flex;
      // justify-content: space-between;
      margin-bottom: 3rem;
      text-align: initial;

      img {
        margin-top: 2rem;
        margin-left: 20px;
      }

      .btn {
        margin-top: 8.5rem;
        background: #2a2a2a;
        display: block;
        color: $whiteColor;
        padding: 0.2rem 1rem;
        margin-left: 1rem;

        &:hover {
          // background: $pinkColor;
        }
      }

      .first {
        background: url("../../images/landing/khyber.svg");
        background-position: center;
        background-repeat: no-repeat;
        width: 26rem;
        height: 13rem;
        padding-top: 1px;
      }

      .second {
        background: url("../../images/landing/cex.svg");
        background-position: center;
        background-repeat: no-repeat;
        width: 26rem;
        padding-top: 1px;
        height: 13rem;
      }
    }
  }
  .wapon {
    .sub-wapon {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 55rem;
      margin: 0 auto;
      margin-bottom: 5rem;
      .mob-wapon {
        display: none;
      }
      .left-dev {
        text-align: left;
        p {
          margin: 0;
          width: 80%;
        }
      }
      .right-dev {
        text-align: right;
        p {
          margin: 0 0 0 auto;
          width: 80%;
        }
      }
      h5 {
        margin-top: 1rem;
      }
    }
  }

  .social {
    margin-bottom: 4rem;
    h1 {
      margin-bottom: 2rem;
    }
    .sub-media {
      display: flex;
      justify-content: space-between;
      width: 60rem;
      flex-wrap: wrap;
      margin: 0 auto;
    }
    .row {
      width: 32rem;
      margin: 0 auto;
      margin-bottom: 4rem;
    }
  }

  .social-link {
    margin-top: 4rem;
    .mob-sub {
      display: none;
    }
    .links {
      .address {
        display: block;
        margin-bottom: 1rem;
      }
      .social {
        img {
          margin-left: 0.4rem;
        }
      }
    }
    .sub-social {
      display: flex;
      justify-content: space-between;
      width: 55rem;
      margin: 0 auto;
      text-align: end;
    }
    .desc {
      margin: 0 0 0 auto;
      width: 80%;
      margin-bottom: 1rem;
    }
    .sub-div {
      // width: 50rem;
      // margin: 0 auto;
      text-align: initial;

      .btn {
        color: $whiteColor;
        padding: 0.35rem 1rem;
        // margin-top: 2rem;
        // margin-bottom: 1rem;

        &:hover {
          // transform: scale(1.1);
          cursor: url("../../images/cursor.svg"), auto;
        }
      }

      .first {
        background: url("../../images/landing/telegrambg.png");
        background-position: center;
        background-repeat: no-repeat;
        width: 24rem;
        padding: 5rem;
        background-size: 100%;
        margin-bottom: 1rem;
        .btn {
          position: relative;
          background: #229ed9;
          right: -8rem;
          top: 3rem;
        }
        .sub {
          position: absolute;
          right: 10%;
          top: 12%;
        }
      }

      .sec {
        background: url("../../images/landing/telebg.png");
        background-position: center;
        background-size: 100%;
        background-repeat: no-repeat;
        width: 24rem;
        padding: 5rem;
        .sub {
          position: absolute;
          bottom: 22%;
          left: 18%;
        }
        .btn {
          position: relative;
          left: -4rem;
          background: #5865f2;
          top: 3.5rem;
        }
      }

      .links {
        width: 12rem;
        margin: 0 0 0 auto;
        @media (max-width: 1300px) {
          right: -29rem;
        }

        .address {
          font-size: 0.9rem;
        }

        .line {
          display: block;
          width: 12rem;
          height: 1px;
          margin-top: 0.5rem;
          margin-bottom: 0.5rem;
          margin-right: 1rem;
          background: rgba(255, 255, 255, 0.5);
        }

        .img {
          margin-top: 0.5rem;
          margin-left: 0.5rem;
        }
      }
    }
  }

  .skipper {
    .react-tabs {
      display: flex;
      align-items: center;
    }

    .line {
      display: inline-block;
      width: 1px;
      height: 29rem;
      background: #7f7f7f;
      margin-left: 0.3rem;
    }

    .sub-team {
      width: 63rem;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .box {
        background: url("../../images/landing/umerbg.svg") center no-repeat;
        background-size: 100%;
        width: 20rem;
        height: 13rem;
        margin-bottom: 1rem;
        .sub-box {
          display: flex;
          width: 88%;
          margin: 0 auto;
          margin-top: 52px;
          text-align: initial;
          p {
            margin-bottom: 0;
          }
          div {
            margin-left: 0.5rem;
          }
          img {
            width: 5rem;
          }
        }
        a {
          margin-left: 14rem;
          margin-top: 1rem;
          display: block;
          img {
            width: 1.5rem;
          }
          &:hover {
            cursor: url("../../images/cursor.svg") 2 2, auto;
          }
        }
      }
      .box2 {
        background: url("../../images/landing/rifaqatbg.svg") center no-repeat;
        background-size: 100%;
      }
      .box3 {
        background: url("../../images/landing/usmanbg.svg") center no-repeat;
        background-size: 100%;
      }
      .box4 {
        background: url("../../images/landing/ehtibg.svg") center no-repeat;
        background-size: 100%;
      }
    }
  }

  .mob,
  .mob-first {
    display: none !important;
  }

  @media (max-width: 767px) {
    .hide {
      display: none;
    }
    .music-btn {
      bottom: 2.3rem;
    }

    .row,
    .react-tabs__tab-panel--selected {
      width: auto !important;
    }
    .banner {
      // background: url('../../images/landing/topbannermb.svg') center no-repeat;
      // background-size: 100%;
      margin-bottom: 0;
      h1 {
        font-size: 1.7rem;
      }
      .btn {
        display: block;
        margin: 0 auto;
        margin-bottom: 1rem;
        width: 12rem;
      }
      h4 {
        font-size: 1rem;
      }
      .desc {
        width: 95% !important;
        font-size: 14px;
      }
      .mobbile-show {
        display: block;
        text-align: center;
        width: 90%;
        margin: 0 auto;
        position: relative;
        top: -32rem;
        margin-bottom: -34rem;
        .main-address-div {
          margin-bottom: 0.5rem;
        }
      }
      .social-info {
        display: block;
        top: 5rem;
        margin-bottom: -21rem;

        .main {
          width: 95%;
        }

        .last-div {
          img {
            margin: 0 auto;
          }
        }
      }
    }
    .battle {
      text-align: center;
      margin-top: 1rem;
      margin-bottom: 0;
      h2 {
        margin-top: 0 !important;
        font-size: 1.3rem;
      }
      .sub {
        position: initial;
        width: 100%;
      }
      .sub1 {
        text-align: end;
        margin-bottom: 1rem;
        p {
          margin: 0 0 0 auto !important;
        }
      }
      .sub2 {
        text-align: left;
        margin-bottom: 1rem;
        margin-top: 2rem;
        p {
          margin: 0 !important;
        }
      }
    }
    .mob {
      display: block !important;
      margin-top: 0;
      width: auto !important;
      height: auto !important;
    }

    .mobile-video {
      display: block !important;
    }
    .web-video {
      display: none;
    }

    .desc {
      width: 95%;
    }

    .game {
      .hide {
        display: none !important;
      }
      .carousal {
        height: 300px;
      }
      .right-btn {
        bottom: 10%;
        right: 30%;
      }
      .left-btn {
        bottom: 10%;
        left: 30%;
      }
      .mob-img2,
      .mob-last-img {
        display: none;
        // margin: 0;
        // width: 100%;
        // margin-top: 3rem;
      }
      .mob-img2 {
        margin-top: 3rem;
      }
      h1 {
        font-size: 25px;
      }
      .css-doq0dk {
        width: 100% !important;
        .youtube-div {
          width: 100%;
          height: 100%;
        }
      }
      .sub-game {
        width: 19rem;
        padding: 1rem 0rem !important;
      }
    }

    .roadmap {
      .row {
        text-align: center;
      }
      .react-tabs {
        hr {
          display: none;
        }
      }
    }

    .audit {
      .sub-audit {
        width: auto !important;
        display: block;
      }
      .btn {
        margin-top: 0 !important;
        margin-bottom: 2rem;
      }
    }

    .ido {
      .heading-div,
      p,
      .last-div {
        width: 95% !important;
        margin: 0 auto;

        p,
        span {
          font-size: 0.8rem;
        }
      }

      .heading-div {
        margin-top: 2rem;
      }

      .last-div {
        margin-top: 1rem;
      }

      .btn {
        margin: 0 auto !important;
      }
    }

    .jet {
      .first {
        background: url("../../images/landing/khybermob.svg") center no-repeat !important;
        background-size: 100% !important;
      }
      .second {
        background: url("../../images/landing/cexmob.svg") center no-repeat !important;
        background-size: 100% !important;
      }
    }
    .about {
      background-size: initial;
    }
    .mobil-video {
      display: initial;
    }
    .web-video {
      display: none;
    }
    .space {
      top: -50rem;
      h1 {
        margin-top: 9rem;
      }
      .sub-space {
        margin-top: 3rem;
      }
      .charge,
      .park {
        width: 95% !important;
        margin: 0 auto;
        margin-bottom: 1rem;
        padding: 0.5rem !important;
      }
      // .park {
      //   background: url('../../images/landing/chargemb.svg') center no-repeat !important;
      //   background-size: 100% 100%;
      // }
      // .charge {
      //   background: url('../../images/landing/parkmb.svg') center no-repeat !important;
      //   background-size: 100% 100%;
      // }
    }
    .partner {
      background: url("../../images/landing/partnershipbgmob.png") no-repeat center;
      background-size: 100%;
    }
    .social-link {
      margin-top: 0rem;
      .first,
      .sec {
        width: 22rem !important;
      }
      .desc {
        width: 95% !important;
      }
    }
    .mob-sub {
      display: block !important;

      .first,
      .sec {
        margin: 0 auto;
        margin-bottom: 1rem;
      }
      .first {
        .btn {
          right: -7rem !important;
        }
      }
    }
    .mode {
      .mob-img-mode {
        display: block;
      }

      .sub-mode {
        text-align: end !important;
        flex-wrap: wrap;
        div {
          width: 95%;
        }
        img {
          width: 100%;
          margin-bottom: 3rem;
        }
        h1 {
          text-align: end !important;
          font-size: 1.5rem;
          margin-top: 1.3rem;
        }
        .btn {
          display: block;
          margin: 0 0 0 auto;
        }
        p {
          width: 95%;
          margin: 1rem 0 0 auto;
          text-align: end;
        }
        .last-mode {
          h1,
          p {
            text-align: left !important;
            margin: 0;
          }
          h1 {
            margin-bottom: 1rem;
          }
          .btn {
            margin: 0;
          }
        }
      }
    }
    .mode-div {
      width: auto !important;
      flex-wrap: wrap;
    }

    .jet {
      margin-top: 0;
      p {
        margin: 0 auto !important;
        margin-bottom: 1rem !important;
        width: 95% !important;
      }
      .main-div {
        width: 95%;
        margin: 0 auto;
        flex-wrap: wrap;

        .first,
        .second {
          width: 22rem;
          margin: 0 auto;
        }
      }
    }
    .wapon {
      .sub-wapon {
        width: 95% !important;
        display: block !important;
        div {
          p {
            width: 95% !important;
          }
        }
      }
    }
    .social {
      margin-bottom: 0;
      h1 {
        font-size: 2rem;
      }
      .sub-media {
        width: auto !important;
        text-align: center;
      }
      .backed-div {
        width: 100%;
      }
      .row {
        margin-bottom: 0;
      }
      img {
        width: 11rem;
        margin-bottom: 1rem;
      }
    }

    .social-link {
      .sub-div {
        width: 95%;
        flex-wrap: wrap;
        margin: 0 auto;
      }

      .links {
        display: none;
      }
    }

    .skipper {
      .react-tabs {
        display: initial;

        ul {
          writing-mode: initial;

          li {
            transform: scale(1);
            height: auto;
          }
        }

        .line {
          display: none;
        }

        .box {
          width: 90%;
          margin: 0 auto;
          margin-top: 1rem;

          .sub-div {
            img {
              width: 5rem;
            }
          }
        }
      }
    }
  }
  @media (max-width: 375px) {
    .banner {
      .social-info {
        top: 3rem;
      }
    }
  }

  @media (max-width: 820px) {
    .row,
    .mode-div,
    .main-div,
    .sub-div,
    hr {
      width: 95% !important;
      margin: 0 auto;
    }
    .slick-slider {
      width: auto !important;
    }
    .slick-next {
      right: -5% !important;
      top: 30%;
      z-index: 11;
    }
    .slick-prev {
      top: 30%;
      z-index: 11;
      left: -5% !important;
    }
    .supported-div {
      margin: 0 auto;
      top: -8rem;
    }
    .every {
      display: block;
      top: 38rem;
      left: 1rem;
      width: 3rem;
    }
    .roadmap {
      background: url("../../images/landing/roadmob.png") center no-repeat;
      background-size: 100%;
      text-align: center;

      .slick-dots {
        bottom: 28rem;

        li {
          border: 1px solid;
          border-radius: 1rem;
        }
        button {
          &::before {
            color: $blackColor !important;
            opacity: 1;
            left: -5;
          }
        }
        .slick-active {
          button {
            &::before {
              color: $whiteColor !important;
              opacity: 1;
            }
          }
        }
      }

      h1 {
        margin-top: 3rem;
      }
      h2 {
        font-size: 20px;
        margin-top: 2rem;
      }
      .main-road {
        display: none;
      }
      .sec-road {
        margin-top: 0;
      }
      .sub-road {
        margin-bottom: 30rem;
      }
      .road-mob {
        display: initial;
        h1 {
          font-size: 3rem;
        }
        .sub-road {
          text-align: center;
          div {
            width: 85%;
            margin: 0 auto;
            span {
              font-size: 14px;
              display: block;
              margin-bottom: 0.4rem;
            }
          }
        }
      }
    }
    .sub-games {
      margin-top: 12rem !important;
      img {
        width: 10rem;
        display: block;
        margin: 1rem auto;
        height: auto !important;
      }
      .images-divv {
        margin-top: 4rem;
        display: block !important;
      }
    }
    .sub-main {
      width: 95%;
      top: 10rem;
      display: flex;
      flex-wrap: wrap;
      left: 0;
      top: 13rem;

      img {
        margin-left: 1rem;
        width: 45%;
        margin-bottom: 1rem;
      }
    }

    .images-div {
      img {
        margin: 0 !important;
      }
    }
    .arrow-gif {
      top: 10rem;
      width: 7%;
      left: 10rem;
      display: none;
    }
    .sub-jet,
    .sub-social,
    .sub-space {
      width: 100% !important;
      display: block !important;
      text-align: center !important;
    }
    .sub-wapon {
      video {
        width: 100%;
      }
      .mob-wapon {
        display: initial !important;
      }
    }
    .social-link {
      p {
        margin: 0 auto !important;
      }
    }

    .logo-img {
      display: none;
    }

    .sub-team {
      width: 100% !important;
      .box {
        margin: 0 auto;
        margin-bottom: 1rem;
      }
    }
    .vr {
      .sub-vr {
        h1 {
          font-size: 25px;
          width: 95%;
          margin: 1rem auto;
        }
        img {
          margin-bottom: 1rem;
        }
        p {
          width: 90%;
          margin: 0 auto;
          margin-bottom: 0.6rem;
        }
      }
    }

    .row-2 {
      width: auto !important;
    }

    .mob-div,
    .every-img {
      display: none;
    }

    .mode-div {
      display: block;

      .sub-mode {
        width: auto !important;
      }
    }

    .main-div {
      .first,
      .second {
        width: 22rem !important;
        margin: 0 auto;
      }
    }

    .mob-address-div {
      display: flex;
      justify-content: space-between;
      align-items: end;

      .mob-first {
        display: flex !important;
      }

      .mob-sec {
        display: initial;

        .address {
          display: none;
        }
        .line {
          display: none;
        }

        .address {
          writing-mode: initial;
        }
      }
    }

    .social-link {
      .links {
        display: none;
      }
    }

    .banner-sub-main {
      gap: 16px;
      left: 50%;
      justify-content: space-around;
      transform: translateX(-50%);
      a {
        width: 47%;
      }
      img {
        width: 100%;
        margin: 0;
      }
    }
  }

  @media (max-width: 767px) {
    .slick-next {
      right: 0% !important;
    }
    .slick-prev {
      left: 0% !important;
    }
  }

  @media (max-width: 1250px) {
    .mob,
    .web,
    .web-loader,
    .mob-loader {
      display: none;
    }

    .laptop,
    .laptop-loader {
      display: initial !important;
    }

    .first-div {
      justify-content: start !important;
    }

    .mob-sec {
      justify-content: end !important;
    }
  }

  .hidden {
    display: none !important;
  }
}

// .react-responsive-modal-root {
//   z-index: 1020 !important;

//   .react-responsive-modal-modal {
//     width: 100% !important;
//     max-width: inherit;
//   }

//   .react-responsive-modal-closeButton {
//     display: none;
//   }

//   .cross {
//     position: absolute;
//     color: white;
//     z-index: 111111111;
//     right: 1%;
//     top: -13%;
//   }
// }


.dark {
  // background: $blackColor 0% 0% no-repeat padding-box;
  background: #000000;
  background-size: 100%;
  opacity: 1;
  height: 77px;
  width: 100%;
  text-align: center;

  @media (max-width:767px) {
    // background: url('../../images/navmob.svg') no-repeat center;
  top: -6px;
  height: 100px;
  }
  // font-family: $Roboto;
  .top-links-div {
    display: none;
    @media(max-width: 767px) {
      // display: initial;
      position: absolute;
      right: 5%;
      img {
        width: 1.5rem;
        margin-left: 9px;    }
    }
    @media(max-width: 820px) {
      // display: initial;
      position: absolute;
      right: 5%;
      img {
        width: 1.5rem;
        margin-left: 9px;    }
    }
  }
.connect {
  display: none;
  @media(max-width: 767px) {
    display: initial;
    width: 100%;
    background: $pinkColor;
    color: $whiteColor;
    position: fixed;
    bottom: 0;
    width: 100%;
    right: 0;
    left: 0;
  }
}
  .navbar-brand {
    position: relative;
    z-index: 1;
    top: 1rem;
    width: 14rem;
    visibility: hidden;
    &:hover {
      cursor: url('../../images/cursor.svg'), auto;

    }
    img {
      width: 90%;
    }
    // @media(max-width: 767px) {
    //   top: 0rem;
    //   position: fixed;
    //   right: 14% !important;
    //   z-index: 1;
    //   img {
    //     width: 12rem;
    //   }
    // }
    
    @media(max-width: 820px) {
      top: 0rem;
      position: fixed;
      right: 23%;
      z-index: 1;
      visibility: inherit !important; 
      img {
        width: 14rem;
      }
    }
  }

  .showit {
    visibility: initial;
  }
  .mob-view {
    display: none;
  }

  .navbar-toggler {
    position: absolute;
    right: 82%;
    top: 21%;
    // background: $pinkColor;
    // background: url('../../images/togler.svg') no-repeat;
  }

  .navbar-toggler:focus {
    outline: none;
  }

  .navbar-toggler-icon {
    background: url('../../images/toggler.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
  }
  .btn:hover {
    transform: scale(1);
  }
  .navbar-collapse {
    width: 34% !important;

    @media (max-width: 820px) {
      background: #1E1E1E 0% 0% no-repeat padding-box;
      z-index: 222222;
      margin-top: 5.7rem;
      text-align: center;
      padding-top: 1rem;

      .mob-view {
        display: initial;
      }

      .connect {
        // color: $orangeColor;
      }
    }
  }

  .active {
    display: block;
    position: absolute;
    top: 4.8rem;
    margin-right: 24px;
    z-index: 1;
  }
  .nav-item {
    a {
      font-weight: 600;
      font-family: $monteserrat;
    }
  }
  .dropdown-item {
    img {
      width: 10rem;
      @media (max-width: 1280px) {
        width: 7rem;
      }
    }
  }

  .left {
   .nav-item {
    height: 4rem;
    padding-top: 1rem;
    // margin-top: -24px;
    margin-right: 3.6rem;
    @media (max-width:1500px) {
      margin-right: 2.5rem;
    }
    @media (max-width:1231px) {
      margin-right: 1.5rem;
      a {
        font-size: 0.8rem;
       }
    }
    @media (min-width:1570px) {
      margin-right: 3.8rem;
   }
   @media (max-width:767px) {
    font-size: 1rem;
 }
   }
  }

  .web-nav {

    .btn {
      // background: $pinkColor;
      color: $whiteColor;
      border-radius: 0;
      font-size: 0.9rem;
         padding: 0.2rem 0.5rem;
         margin-top: -0.1rem;
    }
    .nav-item{
      height: 4rem;
      padding-top: 1rem;
      // margin-top: -24px;
    }
    .nav-item,.dropdown-menu {
     margin-left: 3rem;
     @media (min-width:1570px) {
      margin-left: 5rem;
   }
    }
    .dropdown-menu {
      margin-left: 1.5rem;
      @media (min-width:1570px) {
       margin-left: 3rem;
    }
     }
    
   }
   .dropdown-menu.show {
    text-align: center;
    background: $blackColor;
  }
  
  .nav-item {
    color: $whiteColor;
       a {
          display: block;
        font-size: 1.2rem;
        padding: 0.2rem;
        .dark-img {
          display: none;
        }
        .light2-img {
          display: initial;
        }
        .dark2-img {
          display: none;
        }
        img {
          width: 1.5rem !important;
          margin-right: 0.3rem;
        }
      }
    
    &:hover {
      text-decoration: none;
      background: $pinkColor;
      min-width: 6rem;
      -webkit-transform: scale(1.2);
      @media (max-width:1200px) {
        min-width: 4rem;
      }
      a {
        color: #000000 !important;
        .light-img {
          display: none;
        }
        .dark-img {
          display: initial;
        }
        .light2-img {
          display: none;
        }
        .dark2-img {
          display: initial;
        }
      }
    }
    @media (max-width:1400px) {
     
      
      a {
        font-size: 1rem !important;
      }
    }
    @media (max-width:1290px) {
     

      a {
        font-size: 0.7rem !important;
      }
    }
    @media (max-width: 820px) {
      margin: 0 auto !important;
    }

    .scroller {
      cursor: url('../../images/cursor.svg'), auto !important;

    }
    a {
      // @include magic-border(2px, $orangeColor, 0.3s, 0);
      cursor: url('../../images/cursor.svg'), auto;
      z-index: 1111111;
      color: $whiteColor;

      &:hover {
        text-decoration: none;
      }
    }
  }
  .sky {
    img {
    width: 0.8rem !important;
    }
  }
  .mob-view {
    &:hover {
      background: none;
    }
  }

  .first-item {
    margin-left: 0;
    mix-blend-mode: hard-light;

    a {
      margin-left: -4rem;

      img {
        margin-top: -8px;
      }
    }
  }

  .left,
  .web-nav {
    // margin-top: 1rem;

    .mobile-img {
      @media (max-width: 767px) {
        width: 6%;
      }
    }

    .scroller {
      cursor: pointer;
    }

    .line {
      @media (max-width: 767px) {
        display: none;
      }
    }
  }

  .right {
    display: none;

    @media (max-width: 767px) {
      display: flex;
    }
  }
  @media (max-width:767px) {
    .nav-item {
      margin-bottom: 1rem !important;
      padding-top: 0 !important;
      height: auto !important;
     a {
      // width: 9rem;
      font-size: 1.1rem !important;
     }
    }
  }
}

// .sec-nav::after {
//   content: '';
//   position: absolute;
//   bottom: -48%;
//   left: 0;
//   width: 100%;
//   height: 0;
//   border-bottom: 3.2rem solid $grayClor;

// }
@font-face {
  font-family: "Magistral";
  src: local("Magistral"), url("../fonts/Magistral-Medium.woff") format("woff");
}
.logo-img {
  position: relative;
  top: -5rem;
  z-index: 11111;
  margin: 0 auto;
  margin-bottom: -7rem;
  width: 18rem;

  @media (max-width: 820px) {
    display: none;
  }
}
#loading {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: $blackColor;
  z-index: 99999;
  text-align: center;
  video {
    width: 20rem;
    margin: 13rem;
    @media (max-width: 820px) {
      margin: 1rem;
      margin-top: 13rem;
    }
  }
}

.splash-screen {
  height: 80vh;
}
.btn {
  &:hover {
    cursor: url("../images/cursor.svg") 2 2, auto !important;
  }
}
a {
  &:hover {
    cursor: url("../images/cursor.svg") 2 2, auto;
  }
}
body {
  cursor: url("../images/cursor.svg") 2 2, auto;
  margin: 0;

  .btn:focus {
    outline: none;
    box-shadow: none;
  }
  .btn {
    border-radius: 0;
    transition: transform 0.5s;
  }
}

.hide-for-mobile {
  @include breakpoint-down(medium) {
    display: none !important;
  }
}

.hide-for-desktop {
  @include breakpoint-up(large) {
    display: none;
  }
}

.playBtn {
  background: $pinkColor;
  border: 1px solid $pinkColor;
  margin-left: 1rem;
  transition: color 300ms ease-in-out;
  padding: 0.75rem 1.5rem;
  color: #000000;
  font-weight: 600;
}

.info-bar {
  background: #3e0098;
  background-size: cover;
  margin: 0 auto;
  margin-top: 5rem;
  width: 97%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding: 1rem 2rem;
  border-radius: 1px;
  .btn {
    color: $whiteColor;
    background: $pinkColor;
  }
}
.time-para {
  text-align: center;
  span {
    display: inline-block;
    font-size: 1.5rem;
    margin-left: 3rem;
    color: $pinkColor;
  }
  .days {
    display: block;
    margin-left: 0;
    color: $whiteColor;
    font-size: 1rem;
  }
}
.a-enter-ar-button {
  display: none !important;
}

.download {
  max-width: 800px !important;
  margin: 18rem !important;
  text-align: center !important;
  background: rgba($color: #141414, $alpha: 1) 0% 0% no-repeat padding-box !important;
  opacity: 1;
  @media (max-width: 767px) {
    margin: 10rem 1rem 0 1rem !important;
  }
  .react-responsive-modal-closeButton {
    display: none !important;
  }
  .close-img {
    position: absolute;
    right: 1%;
    top: 1%;
  }
  h1 {
    color: $pinkColor;
    font-family: $monteserrat;
    font-weight: bold;
    font-size: 36px;
  }
  p {
    color: $whiteColor;
    font-family: $monteserrat;
    width: 80%;
    margin: 0 auto;
  }
  .sub-main {
    display: flex;
    justify-content: space-evenly;
    width: 90%;
    flex-wrap: wrap;
    margin: 0 auto;
    margin-top: 2rem;
    img {
      width: 47%;
    }
  }
}

.audit {
  background: #000000;
  color: $whiteColor;
  .logo-img {
    margin-bottom: -9rem;
  }
  .limited,
  .sale,
  .program {
    background: url("../../images/audit/auditBg.png") no-repeat center;
    background-size: cover;
    padding: 3rem 0;
    @media (max-width: 820px) {
      background: url("../../images/audit/auditBgMobile.png") no-repeat center;
      padding: 2rem 0;
    }

    .main {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 75%;
      margin: 0 auto;
      @media (max-width: 2300px) {
        width: 80%;
      }
      .sub-branch,
      .sub-hype {
        text-align: left;
        h2 {
          font-family: $tit;
          letter-spacing: 2px;
          font-size: 2.3rem;
        }
        h1 {
          font-size: 3.6rem;
          color: #f7d613;
          font-family: $tit;
          font-weight: bold;
        }
        p {
          font-family: $monteserrat;
          width: 70%;
          color: $whiteColor;
          @media (max-width: 1920px) {
            width: 95%;
          }
        }
        .btn {
          background: #f7d613;
          border: 1px solid #f7d613;
          color: $blackColor;
          font-weight: 600;
          margin-bottom: 4rem;
          padding: 0.5rem 2rem;
          margin-right: 1.5rem;
        }
        .btn-yellow {
          background: none;
          color: #fff;
        }
        span {
          display: block;
          font-family: $monteserrat;
          margin-bottom: 0.5rem;
          a {
            color: $pinkColor;
            text-decoration: underline;
          }
          img {
            width: 1rem;
            margin-right: 1rem;
          }
        }
      }
      .sub-hype {
        text-align: right;
        p {
          margin-left: auto;
        }
        h1 {
          color: #04d9ff;
        }
        .btn {
          background: #04d9ff;
          border: 1px solid #04d9ff;
        }
        .active-btn {
          background: none;
          color: #fff;
          margin-right: 0;
          img {
            width: 0.8rem;
            margin-right: 0.5rem;
            margin-bottom: 0.1rem;
          }
        }
      }
      .circle {
        width: 55%;
      }
    }
    @media (max-width: 1600px) {
      .main {
        .sub-branch,
        .sub-hype {
          h1 {
            font-size: 2.5rem;
          }
          h2 {
            font-size: 1.8rem;
          }
          p {
            width: 90%;
          }
          .btn {
            font-size: 0.7rem;
          }
        }
      }
    }
    @media (max-width: 1200px) {
      .main {
        .sub-branch,
        .sub-hype {
          h1 {
            font-size: 2rem;
          }
          h2 {
            font-size: 1.5rem;
          }
          p {
            width: 90%;
          }
          .btn {
            font-size: 0.65rem;
            padding: 0.5rem 1rem;
          }
        }
      }
    }
    @media (max-width: 820px) {
      .main {
        p {
          margin: 0 auto;
          margin-bottom: 1rem;
          font-size: 0.9rem;
          margin-bottom: 1.5rem;
        }
        .circle {
          width: 100%;
          margin-bottom: 2rem;
        }
        .sub-branch,
        .sub-hype {
          text-align: center;
          .btn {
            margin-bottom: 1rem;
            width: 10rem;
            margin-right: 1rem;
          }
        }
        .sub-hype {
          h2 {
            margin-top: 2rem;
          }
        }
      }
    }
  }
  .sale {
    background: url("../../images/audit/auditBgTwo.png") no-repeat center;
    background-size: cover;
    padding: 1.5rem 0;
    @media (max-width: 820px) {
      background: url("../../images/audit/auditBgTwoMobile.png") no-repeat
        center;
      padding: 2rem 0;
      .main {
        .sub-hype {
          margin-bottom: 3rem;
          .btn,
          .active-btn {
            display: block;
            margin: 1rem auto;
            width: 65%;
          }
        }
      }
    }
  }
  .program {
    background: none;
    .main {
      .circle {
        max-height: 100%;
        @media (max-width: 1800px) {
          max-height: 44rem;
        }
      }
      .sub-hype {
        h1 {
          color: #5ac414 !important;
        }
        .images-div {
          display: flex;
          width: 75%;
          margin-left: auto;
          @media (max-width: 820px) {
            width: 100%;
            div > img {
              margin-bottom: 1rem !important;
            }
          }
        }
        img {
          width: 90%;
          // margin-top: 1rem;
        }
      }
    }
  }
}

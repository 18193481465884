.auth {
    background: black;;
    color: $whiteColor;

    .main {
        width: 80%;
        margin: 6rem auto;
        display: flex;
        align-items: center;
        justify-content: center;
        .first-div {
            width: 45%;
        }
    }

    h1 {
        font-family: $tit;
        font-weight: 600;
        color: #FFA204;
        font-size: 3rem;
    }
    h2 {
        font-family: $monteserrat;
        font-weight: 600;
    }
    p {
        color: rgba($color: $whiteColor, $alpha: 0.8);
    }

    .input-group {
        width: 80%;
    }
    .form-control {
        border: 1px solid #FFA204;
        color: $whiteColor;
        margin-right: 5px;
        background: rgba($color: #000000, $alpha: 0.5);
    }
    button {
        background-color: #FFA204;
        border: 1px solid #FFA204;
        color: black;
        font-family: $monteserrat;
        font-weight: 600;
    }
}
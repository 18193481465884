.footer {
  margin-top: 2rem;
  text-align: initial;
  .btn:hover {
    transform: scale(1);
  }
  .btn {
    background: url("../../images/footerbtn.svg") no-repeat center;
    background-size: 100%;
    width: 37%;
    text-transform: uppercase;
    padding: 0.5rem 0.275rem;
  }

  .first-link-div {
    width: 900px;
    margin: 0 auto;
  }
  .line-foot {
    display: block;
    background-color: $pinkColor;
    height: 1rem;
    width: 1px;
    margin-top: 4px;
  }
  .fly {
    position: absolute;
    right: 2%;
    top: 2%;
    cursor: url("../../images/cursor.svg"), auto;
    img {
      width: 1rem;
    }
  }
  .input-div {
    width: 63%;
  }
  .email {
    margin-bottom: 1rem;
    display: block;
  }
  hr {
    border-top: 1px solid $pinkColor;
  }

  p {
    font-size: 0.9rem;
    color: rgba($color: $whiteColor, $alpha: 0.7);
  }

  .footle-last {
    display: flex;
    justify-content: space-between;
    align-items: end;

    .social-images {
      img {
        margin-left: 1rem;
        width: 24px;

        @media (max-width: 820px) {
          margin-left: 3rem;
        }
        @media (max-width: 600px) {
          margin-left: 1rem;
        }
      }
    }
  }
  .logo-div {
    .logo {
      width: 17rem;
    }
    text-align: center;
    width: 800px;
    align-items: center;
    p {
      margin-left: 0.5rem;
    }
  }

  .social-div {
    text-align: end;
    margin-top: 8rem;

    img {
      margin-right: 0.5rem;
      width: 2.1rem;
    }
  }

  input {
    background: url("../../images/inputbg.svg") no-repeat center;
    background-size: 100%;
    height: calc(3.25rem + 6px);
    border: 0;
    border-radius: 0;

    &:focus {
      background: url("../../images/inputbg.svg") no-repeat center;
      background-size: 100%;
      border: 0;
      border-radius: 0;
      color: rgba($color: $whiteColor, $alpha: 1);
      outline: none;
      box-shadow: none;
    }
  }

  ::placeholder {
    color: rgba($color: $whiteColor, $alpha: 0.7);
  }

  ul {
    margin-bottom: 1rem !important;
    justify-content: space-between;
  }

  li {
    a {
      color: rgba($color: $whiteColor, $alpha: 0.9);
      font-size: 0.9rem;
      padding: 0.7rem;
      text-decoration: none;
      &:hover {
        background: $pinkColor;
        color: $blackColor;
        cursor: url("../../images/cursor.svg"), auto;
      }
    }
  }

  .first {
    margin-left: 0;
  }
  .row-2 {
    background: #2a2a2a;
  }
  .right-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      width: 50%;
      margin: 0;
      color: rgba($color: $whiteColor, $alpha: 0.7);
    }
  }
  .mobb-div,
  .mobb,
  .mob-fly {
    display: none;
  }

  .last-span {
    text-align: center;
    display: block;
    @media (max-width: 767px) {
      position: absolute;
      bottom: 2%;
    }
  }
  @media (max-width: 767px) {
    text-align: center;
    .hide,
    .fly {
      display: none;
    }
    .btn {
      font-size: 0.8rem;
    }
    .mob-fly {
      display: block;
      margin-top: 2rem;
    }
    .mobb-div,
    .mobb {
      display: initial;
      img {
        margin-left: 0.5rem;
        width: 2.5rem;
      }
    }
    .logo {
      width: 10rem !important;
    }
    h1 {
      font-size: 1.4rem;
    }
    li {
      margin-bottom: 1rem;
    }
    .logo-div,
    .right-div {
      display: block;
      p {
        width: auto;
      }
    }
    .marginMobile {
      margin-bottom: 3rem;
    }
  }
  @media (max-width: 820px) {
    width: 100% !important;
    .danger {
      margin: 1rem auto;
    }
    .first-link-div,
    .logo-div {
      width: auto !important;
    }
    .logo-div {
      margin-top: 1rem;
    }
    .footle-last {
      display: block;
    }
    hr {
      margin-bottom: 1rem !important;
    }
  }
}

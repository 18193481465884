.fixed-imge {
  background: url('../../images/toknomics/background.svg');
  height: 100%;
  width: 100%;
  position: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.tokenomics {
  background-color: black;
  // background: url("../../images/landing/background.svg");
  // background-repeat: no-repeat;
  // background-attachment: fixed;
  color: $whiteColor;
  text-align: center;
  @media (min-width: 1800px) {
    // background-size: 100%;
  }
  .desc {
    color: rgba($color: $whiteColor, $alpha: 0.8);
    width: 60%;
    margin: 0 auto;
    margin-bottom: 2rem;
  }
  .heading {
    font-size: 1.9375rem;
    margin-top: 4rem;
  }
  .distribution {
    margin-bottom: 5rem;

  }
  .rows {
    max-width: 70rem;
    margin: 0 auto;

    @media (max-width: 1100px) {
      max-width: 63rem;
    }
    img {
      max-width: 100%;
    }
   
    .sideText {
      writing-mode: vertical-lr;
      font-size: 0.9rem;
      transform: scale(-1);
      color: rgba($color: $whiteColor, $alpha: 0.3);
      align-self: end;
    }
    .textRight {
      text-align: right;
      @include breakpoint-down(small) {
        text-align: center;
        margin-top: 3rem;
      }
    }
    .textLeft {
      text-align: left;
      @include breakpoint-down(small) {
        text-align: center;
        margin-top: 3rem;
      }
    }
   .hidden {
     visibility: hidden;
   }
    table {
      td {
        border: 1px solid $pinkColor;
      }
      .first-td {
        border: 0;
        text-align: end;
        width: 12rem;
      }
      .sec-td {
        text-align: end;
        position: relative;
      }
      .sec-td:after {
        content: "";
        position: absolute;
        background: $pinkColor;
        top: 0;
        bottom: 0;
        height: 95%;
        left: 0;
        animation: 1s ease-out 0s 1 slideInFromLeft;
      }
     
      .td-0::after {
        width: 8%;
      }
      .td-1::after {
        width: 5%;
      }
      .td-2::after {
        width: 3%;
      }
      .td-3::after {
        width: 40%;
      }
      .td-4::after {
        width: 5%;
      }
      .td-5::after {
        width: 15%;
      }
      .td-6::after {
        width: 24%;
      }
    }
    .line {
      display: inline-block;
      width: 1px;
      height: 15rem;
      align-self: end;
      margin: 0 1.2rem;
      background: rgba(255, 255, 255, 0.5);
    }
    .pinkTxt {
      color: $pinkColor;
      font-size: 1.3rem;
      @include breakpoint-down(small) {
        font-size: 1.2rem;
      }
    }
    .jc-evenly {
      justify-content: space-evenly;
      @include breakpoint-down(small) {
        margin: 1rem;
      }
    }
    .jc-center {
      @include breakpoint-down(small) {
        justify-content: center;
      }
    }
    @keyframes slideInFromLeft {
      0% {
        transform: translateX(-100%);
      }
      100% {
        transform: translateX(0);
      }
    }
    .progress-bars {
      width: 11.5rem;
      height: 5rem;
      border: 1px solid $pinkColor;
      position: relative;
      margin-left: 0.5rem;
      animation: 0.5s ease-out 0s 1 slideInFromLeft;
      @media (max-width: 1100px) {
        width: 10.5rem;
      }
      @include breakpoint-down(small) {
        width: 9rem;
        height: 3rem;
      }
      h1,
      p {
        position: relative;
        right: 5px;
        @include breakpoint-down(small) {
          font-size: 0.8rem;
        }
      }
    }

  }
  .distribution {
    h2 {
      text-align: center;
      margin-top: 3rem;
    }
    .main-div {
      display: flex;
      flex-wrap: wrap;
      .table-div {
        width: 93%;
      }
    }
    p {
      width: 60%;
      text-align: center;
      margin: 0 auto;
      margin-bottom: 2rem;
    }
  }
  .vesting {
    text-align: center;
    width: 100%;
   
    p {
      width: 70%;
      margin: 0 auto;
      margin-bottom: 1rem;
    }
  }
  @media (max-width: 820px) {
 
    .hidden {
      display: none;
    }
    .main-div {
      display: block !important;
    }
    p {
      width: auto !important;
    }
    .rows, .first-td {
      width: auto;
    }
  }
}

.selectDisable {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.get-game-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  & > a,
  & > button {
    width: 50% !important;
  }
  & img {
    width: 100% !important;
    padding: 0.5rem;
  }
}

.primary-card {
  background: transparent linear-gradient(180deg, #606060 0%, #131313 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  .card-body,
  .card-header {
    position: relative;
    z-index: 2;
  }
}
.primary-card:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  background: #030303;
  box-shadow: 0px 3px 6px #00000029;
  z-index: 1;
}
.content-wrapper {
  max-width: 1920px;
  margin: 0 auto;
}
.xsmall {
  font-size: 0.85rem;
}

.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 700;
}
.fw-800 {
  font-weight: 800;
}

.popover-container {
  font-family: $monteserrat;
  background: transparent linear-gradient(180deg, #606060 0%, #131313 100%) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  color: white;
  width: 300px;
  text-align: center;
  padding: 20px 18px;

  @media screen and (max-width: 767px) {
    width: 100%;
  }

  ul {
    padding: 0;
    list-style: none;
  }
  ul {
    li {
      display: flex;
      justify-content: space-between;
      p:first-child {
        color: #ffffff7c;
      }
      p {
        margin-bottom: 12px;
      }
      &:last-child {
        p {
          margin-bottom: 0;
        }
      }
    }
  }

  h3 {
    font-weight: 500;
    font-size: 13px;
    margin-bottom: 16px;
  }
}

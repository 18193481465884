// Rank bar component

.rank-bar {
  width: 100%;
  height: 8px;
  background: #1a1a1a;

  .bar {
    padding-left: 42px;
    box-shadow: inset 10px 10px 20px #00000029;
    height: 100%;

    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 0;

      transform: translate(0, -50%);

      width: 0px;
      height: 16px;
      border: 4px solid #ffffff;
    }

    .level-count {
      position: absolute;
      top: 50%;
      left: 17px;
      z-index: 2;
      transform: translate(-50%, -50%);

      span {
        display: block;
        z-index: 1;
        text-align: center;
        line-height: 40px;

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        font-size: 14px;
        font-weight: 700;
        color: #ffffff;
      }
    }
  }
}
.low-xp {
  background: transparent linear-gradient(270deg, #ff0053 0%, #80002a 100%) 0% 0% no-repeat padding-box;
}
.high-xp {
  background: transparent linear-gradient(270deg, #1c68e9 0%, #0e3475 100%) 0% 0% no-repeat padding-box;
}
.medium-xp {
  background: transparent linear-gradient(270deg, #e9c21c 0%, #75670e 100%) 0% 0% no-repeat padding-box;
}
.full-xp {
  background: transparent linear-gradient(270deg, #45e91c 0%, #23750e 100%) 0% 0% no-repeat padding-box;
}

.xp {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    font-size: 22px;
    font-weight: 700;
    margin: 0 7px;
  }
}

.leader-board {
  background: #000000;
  color: $whiteColor;
  font-family: $monteserrat;

  .leader-board-wrapper {
    padding: 0 380px;
    margin: 50px 0;
    margin-bottom: 50px;
    .heading {
      text-align: center;
      h1 {
        font-size: 30px;
        font-weight: bold;
        font-family: $TitilliumWeb-Bold;
      }
      p {
        font-weight: 500;
        margin-bottom: 60px;
      }
    }
    .header-cards {
      gap: 30px 0;
    }
    .primary-card {
      min-height: 148px;
    }
  }
  .getGame {
    width: 80%;
    margin: 0 auto;
  }

  .top-rank {
    .primary-card {
      min-height: 330px;
      position: relative;

      .rank-badge {
        position: absolute;
        top: 3px;
        left: 3px;
        z-index: 3;
      }
    }
    .profile {
      text-align: center;
      border-bottom: 1px solid #ffffff44;
      margin-bottom: 24px;

      img {
        margin: 0 auto;
        width: 109px;
        height: 109px;

        background-color: #151515;

        object-fit: cover;
        object-position: top;
        margin-bottom: 13px;
      }

      .name {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 6px;
      }

      .id {
        font-size: 15px;
        color: #ffffff7d;
        margin-bottom: 6px;
      }
    }
  }
  .xp {
    margin-top: 20px;
  }
}

.top-rank-wrapper {
  gap: 30px 0;
}

@media (max-width: 1600px) {
  .leader-board {
    .leader-board-wrapper {
      padding: 0 260px;
    }
  }
}
@media (max-width: 1400px) {
  .leader-board {
    .leader-board-wrapper {
      padding: 0 180px;
    }
  }
  .getGame {
    width: 95% !important;
  }
}

@media (max-width: 1200px) {
  .leader-board .leader-board-wrapper {
    padding: 0 40px;
  }
}
@media (max-width: 440px) {
  .leader-board .leader-board-wrapper {
    padding: 0 16px;
  }
}

// rank list
.rank-list-card {
  width: 100%;
  min-height: 80px !important;
  padding: 10px 34px;

  .rank-list {
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 30px 0;

    .rank-count {
      width: 50px;
      position: relative;
      span {
        font-size: 23px;
        font-weight: bold;

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .profile {
      display: flex;
      align-items: center;

      img {
        width: 64px;
        height: 64px;

        background-color: #151515;

        object-fit: cover;
        object-position: top;
        margin-right: 25px;
      }

      .name {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 6px;
      }

      .id {
        font-size: 15px;
        color: #ffffff7d;
        margin-bottom: 0;
      }
    }
    .rank-bar {
      width: 100%;
    }
  }
  .xp {
    margin-top: 0px;
  }
}

@media screen and (max-width: 768px) {
  .rank-list-card {
    padding: 16px 20px;

    .card-body {
      padding: 0;
    }
  }
}

// nav styling

.leader-board {
  .logo-img {
    position: relative;
    top: -5rem;
    left: 50%;
    z-index: 11111;
    margin: 0 auto;
    margin-bottom: -5rem;
    width: 18rem;
    transform: translateX(-50%);

    @media (max-width: 820px) {
      display: none;
    }
  }
}

// leaderBoard banner

.leader-board-banner {
  height: 400px;
  width: 52px;
  text-align: center;
  background: #a30000;
  font-weight: 600;
  font-size: 17px;
  color: white;

  position: fixed;
  right: 0;
  top: 30%;

  z-index: 999;

  writing-mode: vertical-rl;
  text-orientation: mixed;

  transform: rotate(180deg);

  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 768px) {
    bottom: 0;
    top: unset;

    writing-mode: horizontal-tb;
    text-orientation: unset;
    transform: unset;
    width: 100%;
    height: 52px;
    font-size: 14px;
  }
}

// pulse

.pulse-circle {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  box-shadow: 0px 0px 1px 1px #dfdfdf1a;

  background-color: rgba(255, 255, 255, 0.4);

  display: flex;
  align-items: center;
  justify-content: center;
}
.inner-circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: white;
  margin: 2px;
}

.pulse {
  animation: pulse-animation 1s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(255, 255, 255, 0.3);
  }
  100% {
    box-shadow: 0 0 0 14px rgba(255, 255, 255, 0);
  }
}

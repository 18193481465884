@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700;800&display=swap");

@import "./font.scss";
@import "./color.scss";
@import "./mixins";
@import "./layout.scss";
@import "./common.scss";

@import "./components/topNav.scss";
@import "./components/footer.scss";

@import "./pages/landing.scss";
@import "./pages/tokenomics.scss";
@import "./pages/marketPlace.scss";
@import "./pages/NFT.scss";
@import "./pages/coming.scss";
@import "./pages/audit.scss";
@import "./pages/skyverse.scss";
@import "./pages/auth.scss";
@import "./pages/leader-board.scss";

body {
  font-family: "Montserrat", sans-serif;
}

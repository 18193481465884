@font-face {
  font-family: "TitilliumWeb-Black";
  src: local("TitilliumWeb-Black"),
    url("../src/fonts/TitilliumWeb-Black.woff") format("woff");
}
@font-face {
  font-family: "TitilliumWeb-Bold";
  src: local("TitilliumWeb-Bold"),
    url("../src/fonts/TitilliumWeb-Bold.ttf") format("woff");
}
@font-face {
  font-family: "TitilliumWeb-SemiBold";
  src: local("TitilliumWeb-SemiBold"),
    url("../src/fonts/TitilliumWeb-SemiBold.ttf") format("woff");
}
@font-face {
  font-family: "TitilliumWeb-Regular";
  src: local("TitilliumWeb-Regular"),
    url("../src/fonts/TitilliumWeb-Regular.ttf") format("woff");
}
@font-face {
  font-family: "EthnocentricRg-Regular";
  font-style: normal;
  font-weight: normal;
  src: local("EthnocentricRg-Regular"),
    url("../src/fonts/ethnocentric\ rg.woff") format("woff");
}

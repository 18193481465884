.comming {
    background: $blackColor;
  color: $whiteColor;
  text-align: center;
//   font-weight: 900;
      font-family: $monteserrat;

.getGame {
    margin-top: 3rem;
    @media (max-width: 767px) {
      margin-top: 0;
    }
    .row {
      width: 85%;
  margin: 0 auto;
    }
    .text {
      text-align: left;
      padding-top: 5rem;
      h1{
        font-size: 2.5rem;
        font-weight: 800;
      }
      p{
        margin-bottom: 1.5rem;
        color: $whiteColor;
        width: 80%;
      }
      
      .images-div {
        display: flex;
        justify-items: center;
        img {
          width: 90%;
          margin-top: 1rem !important;
        }
      }
      @media (max-width: 767px) {
        text-align: center;
        p{
          width: 100%;
        }
      }
    }
  }
.sub-coming {
    display: flex;
    justify-content: space-evenly;
    position: relative;
    top: 5rem;
    z-index: 11;
}
.web-video {
  margin-top: -16rem;
}
.btn {
    background: #2813F7;
    border: 1px solid #2813F7;
    color: $whiteColor;
    padding: 0.5rem 2rem;
    margin-top: .5rem;
}
.inActive {
    background: transparent;
    margin-left: 1rem;
}
  h1 {
      color: #F7D613;
      font-size: 71px;
      font-family: $tit;
      font-weight: bold;
      @media (max-width: 1300px) {
        font-size: 3rem;
      }
  }
  .main-div {
      text-align: left;

      p {
          width: 80%;
      }
  }
  .time-para {
      color: $whiteColor;
      visibility: hidden;
      display: flex;
      justify-content:center;
      align-items: baseline;
      margin-top: 2rem;
      
      span {
          margin-left: 0;
      }
      .coln {
          margin: 0 2rem;
           color: $whiteColor;
      }
      .days {
          text-transform: uppercase;
      }
      .time {
          padding: 0.5rem;
          color: $pinkColor;
          font-weight: bold;
          margin-bottom: 0.6rem;
      }
  }
@media (max-width: 767px) {
    .sub-coming {
        display: block;
        margin-bottom: -24rem;
        .main-div {
            text-align: center;
            p {
                margin: 0 auto;
                width: 90%;
                margin-bottom: 1rem;
            }
        }
    }
    .coln {
        margin: 0 1rem !important;
        display: none;
    }
    .time-para {
        flex-wrap: wrap;
        display: none;
        div {
            width: 9rem;
    margin-bottom: 2rem;

        }
    }
    h1 {
        font-size: 1.5rem;
    }
    video {
        margin-bottom: -3rem;
    }
}
}
.NFT {
  color: $whiteColor;
  background: $blackColor;

  .main {
    margin-top: 5rem;
  }
  .detail {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 95%;
    margin: 0 auto;
    margin-top: 2rem;
    align-items: start;
    .mob {
      display: none;
    }
    h2 {
      color: $pinkColor;
      font-size: 2rem;
    }
    hr {
      width: 95%;
      margin-left: 0;
      border-top: 1px solid rgba($color: $whiteColor, $alpha: 0.3);
    }
    .heading-span {
      color: rgba($color: $whiteColor, $alpha: 0.3);
      letter-spacing: 3.2px;
      font-weight: 400;
      font-size: 0.9rem;
    }
    .price-div {
      display: flex;
      justify-content: space-between;
      width: 98%;
      margin-bottom: 1rem;

      div {
        background: rgba($color: $whiteColor, $alpha: 0.1);
        padding: 1rem;
        width: 24%;
      }
      span {
        color: rgba($color: $whiteColor, $alpha: 0.6);
      }
      h3 {
        font-size: 1.2rem;
        color: $pinkColor;

        span {
          font-size: 0.9rem;
        }
      }
      p {
        color: $pinkColor;
        margin-bottom: 0;
        span {
          font-size: 0.9rem;
          color: $whiteColor;
        }
      }
    }
    .progress-div {
      display: flex;
      justify-content: space-between;
      width: 95%;
      .sub-div {
        width: 45%;
        margin-bottom: 1rem;

        .sub-progress {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 90%;
          span {
            color: $pinkColor;
          }
        }
      }
      .progress {
        background: rgba($color: #2b2b2b, $alpha: 0.5);
        height: 0.3rem;
        border-radius: 0rem;
        width: 80%;
        .progress-bar {
          background: $pinkColor;
        }
      }
    }
    .description-div {
      width: 100%;
      text-align: center;
      .desc {
        width: 95%;
      }
      .track-vertical {
        height: 22rem !important;
        background: #0e1217 !important;
        right: 0;
        div {
          height: 16rem !important;
          background: $pinkColor !important;
        }
      }

      .btn {
        display: block;
        width: 60%;
        margin: 0 auto;
        background: rgba($color: #2b2b2b, $alpha: 0.5);
        color: $whiteColor;
        &:hover {
          background: $pinkColor;
        }
      }
      h2 {
        text-transform: uppercase;
      }
      .feature-div {
        span {
          margin-left: 0.5rem;
        }
      }
    }
  }
  @media (max-width: 820px) {
    .time-para {
      text-align: center;

      span {
        margin-left: 0.5rem !important;
      }
    }
    .detail {
      display: block;
      .mob {
        display: block;
        margin-bottom: 1rem;
      }
      .web {
        display: none;
      }
      .description-div,
      video {
        width: 100%;
      }
    }
  }
}

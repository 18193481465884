.skyverse {
  background: #000000;
  color: $whiteColor;

  .main {
    display: flex;
    justify-content: space-between;
    align-items: end;
    width: 90%;
    margin: 0 auto;
    margin-top: 3rem;
    @media (min-width: 1500px) {
      width: 80%;
    }
    .mob-img {
      display: none;
    }
    .sub-main {
      h2 {
        font-family: $monteserrat;
        font-weight: bold;
      }
      h1 {
        font-size: 67px;
        color: #f7d613;
        font-family: $tit;
        font-weight: bold;
      }
      p {
        font-family: $monteserrat;
        width: 70%;
        margin-bottom: 1.5rem;
      }
      .btn {
        background: #2813f7;
        border: 1px solid #2813f7;
        color: $whiteColor;
        margin-bottom: 4rem;
        margin-top: 1rem;
      }
      span {
        display: flex;
        align-items: baseline;
        font-family: $monteserrat;
        margin-bottom: 0.5rem;
        a {
          color: $pinkColor;
          margin-left: 4px;
          text-decoration: underline;
        }
        img {
          width: 1rem;
          margin-right: 1rem;
        }
      }
    }
    .circle {
      width: 35rem;
    }
  }
  .img-div {
    width: 90%;
    margin: 0 auto;
    margin-top: 7rem;
    @media (min-width: 1500px) {
      width: 80%;
    }
  }
  .oculus {
    display: block;
    width: 70%;
    margin: 0 auto;
    margin-top: 7rem;
  }
  @media (max-width: 820px) {
    .main {
      display: block;
      text-align: center;
      p {
        margin: 0 auto;
        width: 90% !important;
        margin-bottom: 1rem;
      }
      h1 {
        font-size: 2.5rem;
      }
      .btn {
        margin-top: 1rem;
      }
      .circle {
        display: none;
      }
      .mob-img {
        display: block;
        width: 21rem;
      }
    }
    .oculus {
      width: 100%;
    }
    .img-div {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;
      width: 98%;
      img {
        margin-bottom: 0.5rem;
      }
    }
  }
}

.countdown-container {
  display: flex;
  justify-content: center;
}

.countdown-container .digit-label {
  font-size: 12px;
  color: #707070;
  text-transform: uppercase;
  margin-top: 5px;
}

.countdown-container .digit {
  font-size: 30px;
  border: 0.5px solid #707070;
  min-width: 48px;
  height: 48px;
  line-height: 48px;
}

.countdown-container .colon {
  line-height: 48px;
  margin: 0 12px;
}

.countdown-container .colon:last-child {
  display: none;
}

@media (max-width: 440px) {
  .countdown-container .digit {
    font-size: 20px;
    min-width: 36px;
    height: 36px;
    line-height: 36px;
  }
  .countdown-container .colon {
    line-height: 36px;
    margin: 0 8px;
  }
  .countdown-container .digit-label {
    font-size: 10px;
  }
}

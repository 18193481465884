.marketPlace {
  color: $whiteColor;
  background: $blackColor;
  font-family: $orbitron;

  .navbar-brand {
    visibility: inherit !important;
  }
  p {
    color: rgba($color: $whiteColor, $alpha: 0.7);
  }

  .main {
    max-width: 1880px;
    margin-top: 5rem;
  }

  .btn {
    color: $whiteColor;
    background: $pinkColor;
  }

  .search-div {
    background: rgba($color: $darkColor, $alpha: 0.5);
    border: 1px solid rgba($color: #707070, $alpha: 0.1);
    width: 325px;
    margin: 0 0 0 auto;
    span {
      display: block;
      width: 88%;
      margin: 0.7rem auto;
    }
    .input-div {
      width: 95%;
      margin: 0 auto;
      img {
        position: absolute;
        top: 11%;
        right: 6%;
      }
    }
    input {
      background: url('../../images/inputbg.svg') no-repeat center;
    background-size: 100%;
      height: calc(3.25rem + 6px);
      border: 0;
      border-radius: 0;
      margin-bottom: 1rem;
  
      &:focus {
        background: url('../../images/inputbg.svg') no-repeat center;
    background-size: 100%;
    border: 0;
        border-radius: 0;
        color: rgba($color: $whiteColor, $alpha: 1);
        outline: none;
        box-shadow: none;
      }
    }
  }
 

  .sub-row {
    max-width: 1050px;
  }
  ::placeholder {
    color: rgba($color: $whiteColor, $alpha: 0.7);
  }
  .type {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: 0.5rem auto;

    p {
      color: $whiteColor;
    }
    span {
      color: rgba($color: #FFFFFF, $alpha: 0.6);
      text-decoration: underline;
    }
  }
  .filter-div {
    background: rgba($color: $darkColor, $alpha: 0.5);
    border: 1px solid rgba($color: #707070, $alpha: 0.1);
    width: 325px;
    margin: 0.3rem 0 0 auto;

  
    .sub-filter {
      display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 90%;
    margin: 0 auto;
    }
    label {
      // margin-left: 0.8rem;
      margin-bottom: 1rem;
      font-size: 0.9rem;


      .rc-checkbox-inner:after {
        border: 0 !important;
      }

      .rc-checkbox-inner {
        background: rgba($color: #2b2b2b, $alpha: 0.5);
        border-color: rgba($color: #2b2b2b, $alpha: 0.5);
      }

      .rc-checkbox-checked .rc-checkbox-inner {
        background: $pinkColor;
        border-color: $pinkColor;
      }
    }
  }

  .price-div {
    background: rgba($color: $darkColor, $alpha: 0.5);
    border: 1px solid rgba($color: #707070, $alpha: 0.1);
    width: 325px;
    margin: 0.3rem 0 0 auto;
  }
  .rc-slider {
    width: 80%;
    margin: 0 auto;
    margin-bottom: 1rem;
    .rc-slider-step {
      background: $pinkColor;
      height: 5px;
    }
    .rc-slider-handle {
      border-radius: 0;
      border: 0;
      box-shadow: none !important;
      touch-action: none;
      &:active {
      border: 0;
      box-shadow: none;

      }
    }
  }
  .attribute-div {
    background: rgba($color: $darkColor, $alpha: 0.5);
    border: 1px solid rgba($color: #707070, $alpha: 0.1);
    width: 325px;
    margin: 0.3rem 0 0 auto;

    .sub-attribute {
      display: flex;
      justify-content: space-between;
      width: 90%;
      margin: 0 auto;
      margin-bottom: 1.5rem;
      span {
        color: rgba($color: #FFFFFF, $alpha: 0.6);
      }
      .rc-slider {
        width: 60%;
        margin: 0 0 0 auto;
      }
    }
  }
 
  .box {
    padding: 0.5rem;
    margin: 0 auto;
    background: url('../../images/marketplace/box.png') no-repeat center;
    background-size: 100% 100%;
    margin-bottom: 1rem;
    text-align: center;
    height: 22rem;
    cursor: url('../../images/cursor.svg'), auto;
    width: 92%;

    img {
      width: 100%;
      margin-top: 1.2rem;
    }

    .line {
      width: 90%;
      height: 2px;
      display: inline-block;
      background: $pinkColor;
    }
    .heading {
      font-size: 1.2rem;
      color: $whiteColor;
      text-align: start;
      text-transform: uppercase;
      margin: 2rem 0 -0.1rem 1rem;
    }

    p {
      font-size: 0.9rem;
    }

    .feature-div {
      display: flex;
      justify-content: center;
      margin-bottom: 0.5rem;

      span {
        margin-left: 0.5rem;
        font-size: 0.8rem;
      }
    }

    .bottom-div {
      display: flex;
      justify-content: space-between;
      padding: 0.4rem;
      span {
        font-size: 0.9rem;
        color: $whiteColor;
      }
      p {
        font-size: 0.8rem;
      }
      h5 {
        margin-bottom: 0;
      }
    }

  }
.right-filter {
  text-align: end;
    max-width: 1050px;
    margin-bottom: 1rem;
    .btn {
    background: none;
    border: 1px solid $pinkColor;
    margin-right: 1rem;
  }
}
@media (max-width: 1300px) {
  .search-div, .filter-div, .price-div, .attribute-div {
    width: 274px;
  }
}
@media (max-width: 1200px) {
  .box {
    background-size: 100%;
    height: 26rem;
  }
}
  @media (max-width: 767px) {
    .search-div, .filter-div, .price-div, .attribute-div {
      margin: 0 auto !important;
      margin-bottom: 0.5rem !important;
    }
    .search-div {
      img {
        right: 14% !important;
      }
    }
    .right-filter {
      text-align: center;
      .btn {
        margin-top: 1rem;
      }
    }
    .info-bar {
      padding: 1rem;

      .time-para {
        text-align: center;

        span {
          margin-left: 0.5rem !important;
        }
      }

      .btn {
        display: none;
      }
    }
  }

}